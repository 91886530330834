body {
    
    margin: 0;
    background-color: #000000;

    font-family: 'Roboto', sans-serif;
    /* font-family: 'Open Sans', sans-serif; */
    
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    color: #ededed;
}