  
  .ipt::-webkit-input-placeholder { /* Edge */
    color: #343434;
    font-size: 15px;
    font-weight: bold;
  }
  
  .ipt:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #343434;
    font-size: 15px;
    font-weight: bold;
  }
  
  .ipt::placeholder {
    color: #343434;
    font-size: 15px;
    font-weight: bold;
  }
